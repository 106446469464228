/* eslint-disable camelcase */
import React, {
  FC,
  useCallback,
  useEffect,
  useState,
  ChangeEvent,
} from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { TextInput } from '../../inputs/TextInput';
import { t } from '../../../i18n';
import { Textarea } from '../../inputs/Textarea';
import { Captcha } from '../../inputs/Captcha';
import { AttachInput, AttachInputValue } from '../../inputs/AttachInput';
import { Button } from '../../main/Button';
import { sendVacancyRequest } from '../../../utils/api';
import {
  useErrorsWithClearOnInput,
  useLocale,
  usePathPrefix,
  useResetCaptcha,
  useTc,
} from '../../../constants/hooks';
import { VacancySuccessModal } from '../VacancySuccessModal';
import { phoneMask } from '../../../utils/masks';
import { OopsPanel } from '../../common/OopsPanel';
import { TLink } from '../../../i18n/TLink';
import {
  fileTypeValidator,
  fileValidatorAfterSubmit,
} from '../../../utils/fileTypeValidator';

interface IProps {}

const VacancyFreeForm: FC<IProps> = () => {
  const tc = useTc();

  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [vacancy, setVacancy] = useState('');
  const [captcha, setCaptcha] = useState('');
  const [file, setFile] = useState<AttachInputValue>({
    name: '',
    content: null,
  });
  const [errors, , validateFields] = useErrorsWithClearOnInput(
    {
      name,
      captcha,
      phone,
      vacancy,
      email,
      file,
    },
    tc('form.field_required'),
    tc('form.fill_all_fields')
  );
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorFile, setErrorFile] = useState('');

  const [disabled, setDisabled] = useState(false);

  const vacancyHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setVacancy(e.target.value);
  };

  const resetCaptcha = useResetCaptcha(setCaptcha);

  const clearFields = useCallback(() => {
    setEmail('');
    setName('');
    setVacancy('');
    setPhone('');
    setFile({ name: '', content: null });
    resetCaptcha();
  }, [resetCaptcha, setName, setEmail, setFile, setVacancy]);

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setDisabled(true);

      try {
        fileValidatorAfterSubmit(
          !file.content || !fileTypeValidator(file),
          file,
          setErrorFile
        );
        validateFields();

        await sendVacancyRequest({
          name,
          phone,
          email,
          vacancy,
          captcha,
          file: file.content,
          filename: file.name,
        });

        setSuccess(true);
        clearFields();
      } catch (e) {
        setErrorMessage(e.message);
      } finally {
        resetCaptcha();
        setDisabled(false);
      }
    },
    [name, phone, email, vacancy, captcha, file]
  );

  useEffect(() => {
    if (errorMessage) {
      setErrorMessage('');
    }
  }, [name, phone, email, vacancy, file]);

  const closeSuccessModal = useCallback(() => setSuccess(false), []);
  const prefix = usePathPrefix();
  const locale = useLocale();

  return (
    <>
      <OopsPanel isOpen={Boolean(errorMessage)} errorMessage={errorMessage} />
      <form
        className={classnames('content', styles.wrap)}
        onSubmit={onSubmit}
        id="vacancy-free-form"
      >
        {success && <VacancySuccessModal onClose={closeSuccessModal} />}
        <h2>
          {t('vacancies.freeform.title')}
          {locale === 'en' ? (
            <>
              {' '}
              <TLink to={`${prefix}/ru/vacancies/?`}>
                Russian version of the site
              </TLink>
            </>
          ) : (
            <></>
          )}
        </h2>

        <div className={styles.description}>
          {t('vacancies.freeform.description')}
        </div>

        <div className={styles.inputs}>
          <TextInput
            value={name}
            name="name"
            handler={setName}
            label="form.name"
            error={errors.name ? 'form.field_required' : ''}
          />

          <TextInput
            value={phone}
            name="phone"
            handler={setPhone}
            label="form.phone"
            error={errors.phone}
            mask={phoneMask}
          />

          <TextInput
            value={email}
            name="email"
            handler={setEmail}
            label="form.email"
            error={errors.email}
          />
        </div>

        <div className={styles.about}>
          <Textarea
            value={vacancy}
            name="vacancy"
            handler={vacancyHandler}
            error={errors.vacancy ? 'form.field_required' : ''}
            label="vacancies.freeform.tell"
          />
        </div>

        <div className={styles.buttons}>
          <AttachInput value={file} handler={setFile} error={errorFile} />
          <Captcha handler={setCaptcha} error={errors.captcha} />
        </div>

        <div className={styles.submit}>
          <Button disabled={disabled}>
            {t('vacancies.freeform.send_request')}
          </Button>
        </div>
      </form>
    </>
  );
};

export { VacancyFreeForm };
