/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useState, useCallback, useEffect } from 'react';
import validator from 'validator';
import pick from 'ramda/es/pick';
import { Modal } from '../../main/Modal';
import styles from './styles.module.scss';
import { getVacancyIcon } from '../../../utils/vacancy';
import { usePathPrefix } from '../../../constants/hooks';
import { t } from '../../../i18n';
import { VacancyContact } from '../VacancyContact';
import { TeamContactField } from '../../team/TeamContactField';
import { TextInput } from '../../inputs/TextInput';
import { Captcha } from '../../inputs/Captcha';
import { Button } from '../../main/Button';
import { AttachInput } from '../../inputs/AttachInput';
import { sendVacancyRequest } from '../../../utils/api';
import { IPositionsQueryEdge } from '../../../queries/positions/types';

interface IProps {
  onClose: () => void;
  showSuccess: () => void;
  contacts: Record<string, string>;
  position: IPositionsQueryEdge['node'];
}

// We don't need this component anymore, since we have page for it

const VacancyModal: FC<IProps> = ({
  onClose,
  showSuccess,
  contacts,
  position: {
    frontmatter: { quality, title, business, type },
    html,
  },
}) => {
  const prefix = usePathPrefix();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [captcha, setCaptcha] = useState('');
  const [file, setFile] = useState<{
    name: string;
    content: string | ArrayBuffer | null;
  }>({
    name: '',
    content: null,
  });
  const [errors, setErrors] = useState<Record<string, string>>({});

  const validateFields = useCallback(() => {
    const validation = {
      name: !name.length ? 'errors.fill_this_field' : '',
      email: !validator.isEmail(email) ? 'errors.fill_this_field' : '',
      captcha: !captcha ? 'errors.fill_this_field' : '',
    };

    setErrors(validation);

    return !Object.values(validation).some((field) => field);
  }, [name, captcha, email, setErrors]);

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      const phone = '';
      if (!validateFields()) return;

      const result = await sendVacancyRequest({
        email,
        name,
        captcha,
        phone,
        file: file.content,
        filename: file.name,
        vacancy: [quality, business, title].filter((el) => el).join(' '),
      });

      if (!result.errors) {
        onClose();
        showSuccess();
        return;
      }

      // eslint-disable-next-line no-shadow
      const errors = pick(['name', 'email', 'file', 'captcha'], result.errors);

      if (Object.prototype.hasOwnProperty.call(errors, 'captcha')) {
        (window as any).grecaptcha.reset();
      }

      if (Object.keys(errors).length) {
        setErrors(errors);
      } else {
        console.log('OOPS');
      }
    },
    [validateFields, email, name, captcha, file, onClose, showSuccess]
  );

  useEffect(() => setErrors({ ...errors, name: '' }), [name]);
  useEffect(() => setErrors({ ...errors, email: '' }), [email]);
  useEffect(() => setErrors({ ...errors, captcha: '' }), [captcha]);
  useEffect(() => setErrors({ ...errors, file: '' }), [file.content]);

  return (
    <Modal onClose={onClose}>
      <div className={styles.content}>
        <div className={styles.close} onClick={onClose}>
          <img src={`${prefix}/vacancy/close.svg`} alt="x" />
        </div>

        <div className={styles.header}>
          <div
            className={styles.thumb}
            style={{
              backgroundImage: `url('${prefix}${getVacancyIcon(type)}')`,
            }}
          />

          <div className={styles.info__text}>
            <div className={styles.info__quality}>{quality}</div>

            <div className={styles.info__type}>
              <div className={styles.info__title}>{title}</div>
              <div className={styles.info__business}>{business}</div>
            </div>
          </div>
        </div>

        <div
          className={styles.info__html}
          dangerouslySetInnerHTML={{ __html: html }}
        />

        <div className={styles.contacts}>
          <VacancyContact
            title="contacts.hr_name"
            value={contacts.name}
            icon={`${prefix}/vacancy/name.svg`}
          />

          <VacancyContact
            title="contacts.email"
            value={contacts.email}
            href={`mailto:${contacts.email}`}
            icon={`${prefix}/vacancy/email.svg`}
          />

          <VacancyContact
            title="contacts.phone"
            value={contacts.phone}
            href={`tel://${contacts.phone}`}
            icon={`${prefix}/vacancy/phone.svg`}
          />

          <div className={styles.contacts__rest}>
            <TeamContactField icon="facebook">
              {contacts.facebook}
            </TeamContactField>

            <TeamContactField icon="linkedin">
              {contacts.linkedin}
            </TeamContactField>

            <TeamContactField icon="telegram">
              {contacts.telegram}
            </TeamContactField>

            <TeamContactField icon="whatsapp">
              {contacts.whatsapp}
            </TeamContactField>
          </div>
        </div>

        <h2 className={styles.inputs_title}>Send request</h2>

        <form onSubmit={onSubmit} id="vacancy-form">
          <div className={styles.inputs}>
            <TextInput
              value={name}
              handler={setName}
              name="name"
              label="contacts.name"
              error={errors.name}
            />

            <TextInput
              value={email}
              handler={setEmail}
              name="email"
              label="contacts.email"
              error={errors.email}
            />
          </div>

          <div className={styles.buttons}>
            <AttachInput value={file} handler={setFile} error={errors.file} />

            <Captcha handler={setCaptcha} error={errors.captcha} />

            <Button>{t('contacts.send_request')}</Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export { VacancyModal };
