import React, { FC, MouseEventHandler } from 'react';
import path from 'path';
import { getVacancyIcon } from '../../../utils/vacancy';
import { t } from '../../../i18n';
import { usePathPrefix } from '../../../constants/hooks';
import styles from './styles.module.scss';
import { removeHtmlTags } from '../../../utils/dom';
import { TLink } from '../../../i18n/TLink';

interface IProps {
  index: number;
  showModal: (i: number) => MouseEventHandler;
  type: string;
  quality: string;
  title: string;
  business: string;
  description: string;
  slug: string;
}

const VacancyCard: FC<IProps> = ({
  type,
  quality,
  title,
  business,
  description,
  slug,
}) => {
  const prefix = usePathPrefix();

  const qualityBusiness = [quality, business].filter((el) => !!el).join(', ');

  const url = `/vacancies/${path.basename(slug)}`;

  return (
    <div className={styles.position}>
      <TLink to={url} />

      <div className={styles.info}>
        <div
          className={styles.thumb}
          style={{
            backgroundImage: `url('${prefix}${getVacancyIcon(type)}')`,
          }}
        />

        <div className={styles.info__text}>
          <div className={styles.info__title}>{title}</div>

          {!!qualityBusiness && (
            <div className={styles.info__business}>{qualityBusiness}</div>
          )}

          {!!description && (
            <div className={styles.info__description}>
              {removeHtmlTags(description)}
            </div>
          )}
        </div>
      </div>

      <div className={styles.more}>
        <span>{t('vacancies.detail_information')}</span>

        <svg
          width="8"
          height="12"
          viewBox="0 0 8 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.590027 10.59L5.17003 6L0.590027 1.41L2.00003 0L8.00003 6L2.00003 12L0.590027 10.59Z"
            fill="#FFDB72"
          />
        </svg>
      </div>
    </div>
  );
};

export { VacancyCard };
